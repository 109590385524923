import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "./App.css";
import FirstTimeLogin from "./components/AppComponents/FirstTimeLogin";
import ForgotPassword from "./components/AppComponents/ForgotPassword";
import ResetPassword from "./components/AppComponents/ResetPassword";
import SignInContainer from "./components/AppComponents/SignInContainer";
import VerifiedPage from "./components/AppComponents/VerifiedPage";
import VerifyNotificationPage from "./components/AppComponents/VerifyNotificationPage";
import MainContainer from "./containers/MainContainer";
import {ProfileContainer} from "./containers/ProfileContainer";
import SignupContainer from "./containers/SignupContainer";
import SubscriptionContainer from "./containers/SubscriptionContainer";
import {PrivateRoute} from "./helpers/PrivateRoute";
import InjectSessionComponent from "./components/AppComponents/InjectSessionComponent";
import AppsumoSignupContainer from "./containers/AppsumoSignupContainer";

function App() {
    return (
        <div className="App">
            <ToastContainer hideProgressBar={true}/>
            <Switch>
                <Route exact path="/session/:token" component={InjectSessionComponent}/>
                <Route exact path="/signin" component={SignInContainer}/>
                <Route exact path="/signin/slybroadcast" component={SignInContainer}/>
                <Route exact path="/signup" component={SignupContainer}/>
                <Route exact path="/signup/appsumo" component={AppsumoSignupContainer}/>
                <Route exact path="/verify-email" component={VerifyNotificationPage}/>
                <Route exact path="/verify-email/:token" component={VerifiedPage}/>

                <Route exact path="/forgot-password" component={ForgotPassword}/>
                <Route exact path="/reset-password/:token" component={ResetPassword}/>

                <Route exact path="/!/verify-email" component={VerifyNotificationPage}/>
                <Route exact path="/!/verify-email/:token" component={VerifiedPage}/>

                <Route exact path="/!/forgot-password" component={ForgotPassword}/>
                <Route exact path="/!/reset-password/:token" component={ResetPassword}/>
                
                <PrivateRoute exact path="/welcome1" component={FirstTimeLogin}/>
                <PrivateRoute exact path="/account" component={ProfileContainer}/>
                <PrivateRoute exact path="/subscription" component={SubscriptionContainer}/>
                <PrivateRoute path="/" component={MainContainer}/>

            </Switch>
        </div>
    );
}

export default withRouter(App);
