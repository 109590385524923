/* eslint-disable */
import React, {Component} from "react";
import {Link} from "react-router-dom";
import SignupServices from "../../Services/SignupServices";
import NavbarFull from "./NavbarFull";

const signupServices = SignupServices.getInstance();

export default class VerifiedPage extends Component {
    componentDidMount(){
        signupServices.verifyEmail(this.props.match.params.token)
            .then()
            .catch()
    }
    render() {
        return (
            <div>
                <NavbarFull></NavbarFull>
                <div className=" mt-4">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 offset-md-3 mt-4">
                            <h2>Verified.</h2>
                            <Link to="/"> Go to slyText.</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
