/* eslint-disable */
import React from 'react'
import {Link} from 'react-router-dom';
import AuthServices from '../../Services/AuthServices';
import logo from '../../assets/images/logo.png'

const authServices = AuthServices.getInstance();

export default function NavbarFull(props) {
    return (
        <nav className="navbar shadow navbar-light bg-light position-static">
                <a href={props.signedOut?"/":"/app"} className="nav-brand"><img src={logo} height="40" alt=""></img></a>

            <ul className="navbar-nav mr-auto">
                <li className="nav-item acive">
                    {props.signedOut ? "" :
                        <Link to="/"
                              className="btn btn-light round mr-1 pull-left">
                            <i className="fas fa-home text-muted"></i>
                        </Link>
                    }
                </li>

            </ul>

            <div className="inline">

            {props.signedOut?
                    <React.Fragment>
                        <Link   to="/signin"
                            className="btn btn-light text-primary  mr-1" >
                            Log In
                        </Link>
                        <Link   to="/signup"
                            className="btn btn-light text-primary  mr-1" >
                            Sign Up
                        </Link>
                    </React.Fragment>
                    :
                    <React.Fragment>

                        <div className="btn-group dropleft">
                            <button type="button"
                                    className="btn btn-light round" 
                                    data-toggle="dropdown" 
                                    aria-haspopup="true" 
                                    aria-expanded="false">
                                        <i className="fas fa-cog text-muted"></i>
                            </button>
                            
                            <div className="dropdown-menu">
                            
                                <Link to="/account" 
                                    className="dropdown-item" href="#">Account</Link>
                                <Link to="/subscription"
                                    className="dropdown-item" href="#">Subscription</Link>

                                <div className="dropdown-divider"></div>
                                <button className="dropdown-item" href="#"
                                    onClick={()=>{
                                        authServices.signOut();
                                        window.location.href="/app";
                                    }}>Sign Out</button>
                            </div>
                        </div>
                    </React.Fragment>
                }
                </div>

            </nav>
    )
}
