/* eslint-disable */
import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import MessageServices from "../../Services/MessageServices";
import {formatPhoneNumber} from "../../helper";
import {
    escapeDoubleQuotes,
    formatTime,
    getDate,
    getTime,
    messageDeliveryStatus,
    sortTimeStamp
} from "../../helpers/FormattingHelper";
import {CSVLink} from "react-csv";


const messageServices = MessageServices.getInstance();

export default class DeliveryStatusModalComponent extends Component {

    componentWillMount() {
        this.setState({
            status: []
        });
    }

    componentWillReceiveProps(props) {
        if (props.show && props.message && props.message.id) {
            messageServices.getDeliveryStatus(props.message.id)
                .then(res => {
                    this.setState({
                        status: sortTimeStamp(res, "CreatedAt")
                    });
                });
        }
    }

    render() {
        const costPerMessage = this.props.message.fileHash ? 3 : Math.ceil(this.props.message.message.length / 160);
        let cost = costPerMessage * this.state.status.length;
        if (this.state.status.length && this.state.status[0].Message) {
            cost = this.state.status.reduce((pre, cur) => this.props.message.fileHash ? (pre + 3) : (pre + Math.ceil(cur.Message.length / 160)), 0)
        }
        const spamThreshold = 5;
        const campaignStopped = this.props.group_count > spamThreshold && this.state.status.length === spamThreshold && this.state.status.filter(s => s.Status === "SUCCESS").length === 0;
        return (
            <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{borderRadius: "1.5rem"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delivery status <span className="font-italic">({this.props.group_count} members)</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {campaignStopped && <div>
                        <h5>Your campaign has been stopped.</h5>
                        <p>It appears that your text message contains keywords that have been marked as spam. Please adjust your text message and try again.</p>
                        <p>Tip: Avoid words property, for sale, homeowners, house and the like.</p>
                        <hr/>
                    </div>}
                    <h5 className="text-secondary text-center">

                        <small>Delivered: {this.state.status.filter(s => s.Status === "SUCCESS").length} |
                            Failed: {this.state.status.filter(s => s.Status !== "SUCCESS").length} |
                            Total Credits: {cost}
                        </small>
                        <br/>
                        {!!this.state.status.length && <CSVLink data={this.state.status.map(s => ({
                            Name: s.Name,
                            Recipient: s.PhoneNumber,
                            Status: messageDeliveryStatus(s.Status),
                            Message: escapeDoubleQuotes(s.Message),
                            Date: getDate(s.CreatedAt),
                            Time: getTime(s.CreatedAt),
                        }))}
                                 filename={"slytextDeliveryReport.csv"}
                                 headers={[
                                     {label: "Name", key: "Name"},
                                     {label: "Phone number", key: "Recipient"},
                                     {label: "Status", key: "Status"},
                                     {label: "Message", key: "Message"},
                                     {label: "Date", key: "Date"},
                                     {label: "Time", key: "Time"},
                                 ]}>
                            <button className="btn btn-secondary btn-sm text-white mt-2">Download CSV</button>
                        </CSVLink>}

                    </h5>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Recipient</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.status.map((s, i) =>
                            <tr className={s.Status === "SUCCESS" ? "text-success" : "text-danger"}
                                 key={"Delivery_key" + i}>
                                <td>
                                    {formatPhoneNumber(s.PhoneNumber)}
                                </td>
                                <td>
                                    {messageDeliveryStatus(s.Status)}
                                </td>
                                <td>
                                    {s.Message}
                                </td>
                                <td>
                                    {formatTime(s.CreatedAt)}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}



