// formatPhoneNumber removes any non digit character from the input character
export function formatPhoneNumber(phoneNumberString) {
    let matchReg;
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    matchReg = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
    let match = cleaned.match(matchReg);
    if (match) {
        let intlCode = (match[1] ? "+1 " : "");
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumberString;
}

// extractPhoneString removes any non digit character from the input character
export function extractPhoneString(phoneNumber) {
    let phone = phoneNumber.replace(/\D/g, "");
    let l = phone.length;
    if (l < 7 || l > 15) {
        // too short or too long
        return "";
    }
    if (l === 10 && phone[0] > "1") {
        phone = "1" + phone;
    }
    return phone;
}

// convertTitleCaseString converts a string to "Proper" case (caution this can eliminate mid word uppercase, a la McDonalds)
export function convertTitleCaseString(name) {
    // if only 2 letters are uppercase, then return as is
    let numUppercase = name.replace(/[^A-Z]/g, "").length;
    if (numUppercase <= 2) return name;

    // otherwise we convert 
    let str = name.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );

    return str;

}

// formatTime returns  a formatted local timestamp
//  input: Time in UTC
export function formatTime(time) {
    let d = new Date(time);
    let options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
    };
    return d.toLocaleString("en-US", options);
}

// getDate returns  a formatted date in local time
//  input: Time in UTC
export function getDate(time) {
    let d = new Date(time);
    let options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    };
    return d.toLocaleString("en-US", options);
}

// getTime returns  a formatted time in local time
//  input: Time in UTC
export function getTime(time) {
    let d = new Date(time);
    let options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true
    };
    return d.toLocaleString("en-US", options);
}

// sortTimeStamp returns  sorted json based on timestamp
//  input:
//      json: json list
//      key: the key denoting timestamp to compare on
//      asc: 0 to get descending order. default:1
export function sortTimeStamp(json, key, asc = 1) {
    return json.sort(function (a, b) {
        if (asc === 1) {
            if (a[key] > b[key]) {
                return 1;
            } else if (a[key] < b[key]) {
                return -1;
            } else {
                return 0;
            }
        } else {
            if (a[key] < b[key]) {
                return 1;
            } else if (a[key] > b[key]) {
                return -1;
            } else {
                return 0;
            }
        }
    });
}

// messageDeliveryStatus returns user readable delivery status
export function messageDeliveryStatus(status) {
    switch (status) {
        case "SUCCESS":
            return "Success";
        case "STATUS_BLOCKEDSPAM":
            return "Marked as Spam";
        case "WORD_BLOCKED":
        case "Blocked":
            return "Blocked";
        default:
            return "Failure";
    }

}

// Get difference between two strings
export function getDifference(a, b) {
    let i = 0;
    let j = 0;
    let result = "";

    while (j < b.length) {
        if (a[i] !== b[j] || i === a.length)
            result += b[j];
        else
            i++;
        j++;
    }
    return result;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function escapeDoubleQuotes(string) {
    if (string) {
        string = string.replace(/"/g, '""');
    }
    return string;
}