/* eslint-disable */
export default class NotificationHelpers{
    static SIGNUP_500 = "Internal server error. Please contact us to sign up.";
    static INVALID_EMAIL = "Invalid email address.";
    static PASSWORD_MISMATCH_ERROR = "Passwords do not match. Please try again.";
    static INVALID_CARD = "Invalid card details. Please try again.";
    static UNABLE_TO_SUBSCRIBE = "Error subscribing to plan. Please try again.";
    static SUCCESSFUL_SIGN_UP = "Success! Your account was account.";
    static RESET_LINK_SENT = "If such a user exists, a reset link will be sent.";
    static ERROR_RESET_LINK = "Password link could not be sent. Please contact us.";
    static PROFILE_FETCH_ERROR = "Profile could not be retrieved.";
    static SUCCESSFUL_AUTO_REPLY_SAVE = "Auto reply stored successfully.";
    static ERROR_AUTO_REPLY_SAVE = "Auto reply could not saved.";
    static SUCCESS_SCHEDULED_MESSAGE_DELETE = "Successfully deleted a scheduled message.";
    static SUCCESS_CARD_UPDATE = "Successfully updated card information.";
    static ERROR_CARD_UPDATE = "Card information could not be updated. Please try again.";
    static PHONE_PURCHASE_ERROR  =  "Could not purchase phone number: ";
    static PHONE_PURCHASED_SUCCESS  =  "Success! Phone number added to your account: ";
    static SUCCESS_SUBSCRIPTION_CANCEL = "Your subscription has been cancelled.";
    static WARN_SELECT_PREPAID_PLAN = "Please select a prepaid plan.";
    static ERROR_ADD_SMART_REPLY = "Smart reply could not be added.";
    static INVALID_LOGIN_CRED = "Incorrect email address and/or password.";
    static ERROR_DELETE_SCHEDULED_MESSAGE = "Scheduled message could not be deleted.";
    static SUCCESS_UPDATE_SCHEDULED_MESSAGE = "Scheduled message saved.";
    static ERROR_UPDATE_SCHEDULED_MESSAGE = "Scheduled message could not be updated.";
    static ERROR_DELETE_SMART_REPLY = "Smart reply could not be deleted.";
    static INVALID_PASSWORD = "Invalid password.";
    static SUCCESS_PASSWORD_UPDATE = "Password updated.";
    static ERROR_PASSWORD_UPDATE = "Could not update password.";
    static WARN_GROUP_NAME_MANDATORY = "Group name required.";
    static SUCCESS_GROUP_CREATE = "Group created successfully!";
    static ERROR_GROUP_CREATE = "Group could not be created.";
    static SUCCESS_GROUP_UPDATE = "Group updated successfully!";
    static ERROR_GROUP_UPDATE = "Group could not be updated.";
    static WARN_INVALID_PHONE_CSV = "Invalid phone number(s) in list.";
    static ERROR_INVALID_PHONE_NUMBER="Invalid phone number. Please try again.";
    static WARN_PHONE_NUMBER_LONG="Phone number can not be longer than 11 digits.";
    static INSUFFICEINT_DETAILS_CONTACT="Insufficient details. Please provide contact name and phone number.";
    static ERROR_NO_PAYMENT_CARD_FOUND="Payment details not available. Please provide credit/debit card information.";
    static ERROR_INVALID_CSV="Invalid file. Please upload CSV file.";
    static WARN_SCHEDULING_EMPTY_MSG="Cannot schedule an empty message.";
    static ERROR_CSV_NO_DATA="No data found. Please try again.";
    static ERROR_INSERT_BULK_CONTACT="Could not insert contacts. Please try again.";
    static DUPLICATE_EMAIL="Email address is already registered with slytext.";
    static ERROR_SLYB_PHONE="Could not fetch slybroadcast list phones.";
    static INDUSTRIES_FETCH_ERROR="Industry list could not be retrieved.";

    static sucessPhonePurchased(phone) {
        return this.PHONE_PURCHASED_SUCCESS+phone;
    }

    static errorPhonePurchase(phone) {
        return this.PHONE_PURCHASE_ERROR+phone;
    }

    static listImported(length) {
        return `List imported from slybroadcast into ${length} groups.`;
    }

    static listImportedWithError(length,err) {
        return `Could not import the list`;
    }
}