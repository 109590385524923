/* eslint-disable */

import React, {Component} from "react";
import "../../assets/style/settings.css";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {extractPhoneString} from "../../helpers/FormattingHelper";
import NotificationHelpers from "../../helpers/NotificationHelpers";

export default class ContactEditor extends Component {
    constructor(props){
        super(props);
        this.state={
            id: props.contact.id,
            name:props.contact.name?props.contact.name:"",
            phone:props.contact.phone?props.contact.phone:"",
            avatar:props.contact.avatar
        };
        this.toastId = 101;
    }
    componentWillReceiveProps(props){
        this.setState({
            id: props.contact.id,
            name:props.contact.name?props.contact.name:"",
            phone:props.contact.phone?props.contact.phone:"",
            avatar:props.contact.avatar
        });
        if(props.error && props.error !== this.props.error){
            props.error.text().then(err => {
                err = JSON.parse(err);
                if(err.error.includes("Phone Number Is Invalid")){
                    toast.update(this.toastId, {
                        render: NotificationHelpers.ERROR_INVALID_PHONE_NUMBER,
                        type: toast.TYPE.ERROR,
                        className: 'rotateY animated',
                        autoClose: true
                    })
                    
                }
                else if(err.error.includes("Contact with the specified phone number already exists")){
                    toast.update(this.toastId, {
                        render: "Contact already exists.",
                        type: toast.TYPE.ERROR,
                        className: 'rotateY animated',
                        autoClose: true
                    })
                   
                }else{
                    toast.update(this.toastId, {
                        render: err.error,
                        type: toast.TYPE.ERROR,
                        className: 'rotateY animated',
                        autoClose: true
                    })
                }
            })
            // toast.error(props.error)
        }
    };

    handleInputChange=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value,
        })
    };

    handlePhoneInputChange=(e)=>{
        const intlEnabled = this.props.profile && this.props.profile.intl_enabled;
        if(!intlEnabled && e.target.value.length>11){
            if(toast.isActive(101)) {
                toast.update(this.toastId, {
                    render: NotificationHelpers.WARN_PHONE_NUMBER_LONG,
                    type: toast.TYPE.WARNING,
                    autoClose: true,
                });
            }else{
                toast(NotificationHelpers.WARN_PHONE_NUMBER_LONG, {
                    toastId:this.toastId ,
                    type: toast.TYPE.WARNING,
                    autoClose: true,
                });
            }
            return

        }
        this.setState({
            [e.target.name]:e.target.value.replace(/[^0-9]/, '')
        })
    };

    handleSave=(e)=>{
        e.preventDefault();

        let contact=this.state;
        if(contact.phone==null || contact.name==null || contact.phone==="" || contact.name===""){
            if(toast.isActive(101)) {
                toast.update(this.toastId, {
                    render: NotificationHelpers.INSUFFICEINT_DETAILS_CONTACT,
                    type: toast.TYPE.WARNING,
                    autoClose: true,
                });
            }else{
                toast(NotificationHelpers.INSUFFICEINT_DETAILS_CONTACT, {
                    toastId:this.toastId ,
                    type: toast.TYPE.WARNING,
                    autoClose: true,
                });
            }
            return
        }
        contact.phone=extractPhoneString(contact.phone);
        if(this.props.new){

            toast("Creating a contact. Please wait...",{
                toastId: this.toastId
            });
            this.props.addContact(contact);
        }
        else{
            toast("Updating the contact. Please wait...",{
                toastId:this.toastId,
                autoClose:2000
            });
            this.props.updateContact(contact.id,contact);
        }
    };
    render() {
        return (
                <div className="settings-container">

                    {/* Titlebar start */}
                    <div className="conversation-title-container text-light shadow ">
                    <div className=" p-0">
                        {!this.props.new?
                            <div className="row p-1 align-middle text-left">
                                <div className="col-11">
                                    <Link   to="/"
                                            className="btn round d-md-none">
                                        <i className="fas fa-arrow-left"/>
                                    </Link>
                                    <img className=" round p-2"
                                        src={"https://slytext.com"+this.state.avatar}
                                        alt=""
                                        height={50}
                                         width={50}
                                        />
                                
                                    <span className="text-light  title">
                                        {this.props.contact.name} (Contact settings)
                                    </span>
                                </div>
                                <div className="col-1">
                                    <div className="btn-group dropleft right">
                                        <Link   to={"/contacts/"+this.props.contact.id+"/conversation"}
                                                title={"Cancel and close"}
                                                className="btn mt-2 ml-2 round right">
                                            <i className="fas fa-times text-light"/>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row p-1 align-middle text-left">
                                <div className="col-10 p-3">
                                    <Link   to="/"
                                            className="btn round d-md-none">
                                        <i className="fas fa-arrow-left"/>
                                    </Link>
                                    <span className="text-light p-2 text-left title">
                                        Create contact
                                    </span>
                                </div>
                            </div>

                            }
                        
                    </div>
                </div>
                    {/* Titlebar end */}
                    <div className={"col-12 mt-4 "}>
                        <form>
                            <div className="form-group row text-left">
                                    <label htmlFor="inputContactName" className="col-sm-2 col-form-label text-dark">Name</label>
                                <div className="col-sm-10 col-md-6">
                                    <input type="text" 
                                            className="form-control" 
                                            id="inputContactName" 
                                            name="name"
                                            placeholder="Enter contact name"
                                            value={this.state.name} 
                                            onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="form-group row text-left">
                                <label htmlFor="inputPhone"
                                       className="col-sm-2 col-form-label text-dark">
                                    Phone number
                                </label>
                                <div className="col-sm-10 col-md-6">
                                    <input type="phone" 
                                            className="form-control" 
                                            id="inputPhone" 
                                            placeholder="Enter phone number"
                                            name="phone"
                                            value={this.state.phone} 
                                            onChange={this.handlePhoneInputChange}/>
                                </div>
                            </div>
                            <div className={"form-group row text-right"+this.props.new?"hidden":""}>
                                <label htmlFor="inputContactImage"
                                       className="col-sm-2 col-form-label">
                                    Contact image
                                </label>
                                <div className="col-sm-10 col-md-6 text-left">
                                    <img className="image-thumbnail rounded"
                                         src={"https://slytext.com"+this.state.avatar} alt=""/>
                                </div>
                            </div>
                        </form>
                        {/* {this.props.loading?"Updating...":""} */}
                        
                    </div> 
                    <div className={"settings-button-footer"}>
                        <div className="row p-2">
                            <div className="col-sm-12 col-md-3 p-1">
                                {!this.props.new?
                                    <Link to={"/contacts/"+this.state.id+"/conversation"}
                                        className="col-12 btn btn-secondary">
                                            View conversation
                                    </Link>
                                    :
                                    <Link   to="/import/contacts"
                                            className="col-12 btn btn-secondary" href="#">Create multiple contacts</Link>
                                }
                            </div>
                            <div className="col-sm-12 col-md-2 offset-md-5 p-1">
                                {!this.props.new?
                                <button className="col-12 btn btn-danger"
                                        onClick={this.props.onDelete}>
                                        Delete
                                </button>
                                :
                                ""
                                }
                            </div>
                            <div className="col-sm-12 col-md-2 p-1">
                                <button className="col-12 btn btn-primary"
                                        onClick={this.handleSave}>
                                        Save
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
        )
    }
}
