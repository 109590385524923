/* eslint-disable */
import React, {Component} from "react";
import AuthServices from "../../Services/AuthServices";
import {toast} from "react-toastify";
import NavbarFull from "./NavbarFull";
import ReactLoading from "react-loading";
import PhoneServices from "../../Services/PhoneNumberServices";
import {formatPhoneNumber} from "../../helpers/FormattingHelper";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import TagManager from "react-gtm-module";

const authServices = AuthServices.getInstance();
const numberServices = PhoneServices.getInstance();

const tagManagerArgs = {
    gtmId: "GTM-M96W495",
    dataLayer: {
        page: "welcome"
    },
};

export default class FirstTimeLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            industry: "",
            industries: [],
            zip_code: "",
            zip_info: null,
            street: "",
            first_name: "",
            last_name: "",
            phone: "",
            area_code: "",
            loading: false,
            numbers: null
        };
    }

    componentDidMount() {
        TagManager.initialize(tagManagerArgs);
        this.getProfile();
        numberServices.getIndustries()
            .then(res => this.setState({
                industries: res
            }))
            .catch(err => {
                toast.error(NotificationHelpers.INDUSTRIES_FETCH_ERROR);
            });
    }
    
    getProfile = () => {
        authServices.getProfile()
            .then(res => {
                const nameComponents = res.name.split(" ");
                this.setState({
                    profile: res,
                    industry: res.industry,
                    zip_code: res.zip_code,
                    street: res.street,
                    first_name: res.first_name || (nameComponents.length === 2 ? nameComponents[0] : ""),
                    last_name: res.last_name || (nameComponents.length === 2 ? nameComponents[1] : ""),
                    phone: res.phone
                }, this.handleCheckZipCode);
            })
            .catch(err => {
                toast.error(NotificationHelpers.PROFILE_FETCH_ERROR);
            });
    }

    handleInputChange = (e) => {
        const target = e.target;
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value,
            numbers: null
        }, () => {
            if (target && target.name === "zip_code") {
                this.handleCheckZipCode();
            }
        });
    };

    handleCheckZipCode = () => {
        if (this.state.zip_code.length !== 5) {
            return;
        }
        this.setState({zip_info: null});
        numberServices.checkZipCode(this.state.zip_code)
            .then(res => {
                this.setState({
                    zip_info: res
                });
            })
            .catch(err => {
                err.text().then(res => {
                    toast.error(JSON.parse(res).error);
                });
            });
    };

    handleCompleteProfile = () => {
        const profile = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone: this.state.phone,
            street: this.state.street,
            zip_code: this.state.zip_code,
            industry: this.state.industry
        };
        if (!profile.first_name || !profile.last_name || !profile.phone || !profile.street || !profile.zip_code || !profile.industry) {
            toast.error("Please complete all fields");
            return;
        }
        if (!this.state.zip_info) {
            toast.error("Invalid zip code");
            return;
        }
        this.setState({loading: true});
        numberServices.completeProfile(profile)
            .then(res => {
                this.getProfile();
            }).catch(err => {
            err.text()
                .then(res => {
                    toast.error(JSON.parse(res).error);
                });
        }).finally(() => {
            this.setState({loading: false});
        });
    };

    handleFindNumbers = (e) => {
        this.setState({loading: true});
        numberServices.getNumbers(this.state.area_code)
            .then(res => {
                this.setState({
                    numbers: res
                });
            }).catch(err => {
            err.text()
                .then(res => {
                    // toast.error(JSON.parse(res).error)
                    toast.info("Please select a plan.");
                    this.props.history.push("/subscription");
                });
        }).finally(() => {
            this.setState({loading: false});
        });
    };

    handlePhoneNumberPurchase = (phone) => {
        this.setState({loading: true});
        numberServices.purchaseNumber(phone)
            .then(res => {
                toast.success(NotificationHelpers.sucessPhonePurchased(formatPhoneNumber(phone)));
                if (this.state.profile && this.state.profile.appsumo_plan) {
                    this.props.history.push("/subscription");
                } else {
                    window.location.href = "/app";
                }
            })
            .catch(err => {
                toast.error(NotificationHelpers.errorPhonePurchase(formatPhoneNumber(phone)));
            })
            .finally(() => {
                this.setState({loading: false});
            });
    };

    render() {
        const isProfileCompleted = this.state.profile && this.state.profile.brand_id;
        return (
            <div>
                <NavbarFull/>
                <div className=" mt-4">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 offset-md-2 mt-4">
                            {!this.state.profile ?
                                <ReactLoading type="spin"
                                              color={"#000000"}
                                              className={"centered"}
                                /> :

                                <React.Fragment>
                                    <h2>Hello, {this.state.profile.name}</h2>
                                    {!isProfileCompleted && <>
                                        <h5 className="text-muted">Before choosing your slyText phone number, please
                                            verify and complete your profile.</h5>
                                        <div className="container text-left container mt-4">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="first_name" className="field-label-2">
                                                        First name<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus="true"
                                                           maxLength="256"
                                                           name="first_name"
                                                           placeholder="First name"
                                                           id="first_name"
                                                           value={this.state.first_name}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="last_name" className="field-label-2">
                                                        Last name<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus="true"
                                                           maxLength="256"
                                                           name="last_name"
                                                           placeholder="Last name"
                                                           id="last_name"
                                                           value={this.state.last_name}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="industry" className="field-label-2">
                                                        Select your industry<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <select id="industry"
                                                            required
                                                            onChange={this.handleInputChange}
                                                            name="industry"
                                                            className="select-field w-select"
                                                            value={this.state.industry}
                                                    >
                                                        <option>Select your industry</option>
                                                        {this.state.industries.map(ind => <option key={ind}>{ind}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="zip_code" className="field-label-2">
                                                        US zip code (5 digit)<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus="true"
                                                           maxLength="5"
                                                           name="zip_code"
                                                           placeholder="Zip code"
                                                           id="zip_code"
                                                           value={this.state.zip_code}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                    {this.state.zip_info &&
                                                    <p>{this.state.zip_info.city}, {this.state.zip_info.state}, US</p>}
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="street" className="field-label-2">
                                                        US street address<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus="true"
                                                           maxLength="256"
                                                           name="street"
                                                           placeholder="Street address"
                                                           id="street"
                                                           value={this.state.street}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="phone" className="field-label-2">
                                                        US phone number (11 digit, starts with 1)<span
                                                        className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus="true"
                                                           maxLength="11"
                                                           name="phone"
                                                           placeholder="Phone number"
                                                           id="phone"
                                                           value={this.state.phone}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary"
                                                disabled={this.state.loading}
                                                onClick={this.handleCompleteProfile}>Confirm
                                        </button>
                                    </>}
                                    {isProfileCompleted && <>
                                        <h5 className="text-muted">Please choose your slyText phone number.</h5>
                                        <p className="text-muted">Choose from our database of telephone numbers and
                                            assign
                                            your business its own texting phone number. You will be charged $1.50 per number per month.</p>
                                        <p className="text-muted" style={{fontWeight: 700}}> To text enable your own
                                            landline or VoIP number, email us at <a
                                                href="mailto:support@slytext.com?subject=Text enable feature"
                                                style={{color: "#4a90e2"}}>support@slytext.com</a>.</p>
                                        <p className="text-muted" style={{fontWeight: 700}}>Cell phones cannot be text
                                            enabled.</p>
                                        <br/>

                                        <label className="mt-2">Enter area code to find a number</label>
                                        <div className="row">
                                            <div className="col-6 offset-md-3">
                                                <input className="form-control"
                                                       type="phone"
                                                       name="area_code"
                                                       placeholder="E.g. 617 or 857"
                                                       value={this.state.area_code}
                                                       onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="col-2">
                                                <button className="btn btn-primary"
                                                        onClick={this.handleFindNumbers}>Find
                                                </button>

                                            </div>
                                        </div>

                                        {this.state.loading ?
                                            <div style={{"marginTop": "20px"}}>
                                                <ReactLoading type="spin"
                                                              color={"#000000"}
                                                              className={"centered"}/>
                                            </div>
                                            :
                                            <div className="mt-4 mb-4">
                                                {this.state.numbers ?
                                                    <React.Fragment>
                                                        {this.state.numbers.length === 0 ?
                                                            <React.Fragment>
                                                                <h5>
                                                                    No numbers available starting
                                                                    with {this.state.area_code}
                                                                </h5>
                                                            </React.Fragment> :
                                                            <React.Fragment>
                                                                <h5>Click on a number below to select it as your slyText
                                                                    number:</h5>
                                                                <div className="list-group">

                                                                    {this.state.numbers.map(number => (
                                                                        <button
                                                                            className="list-group-item list-group-item-action"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handlePhoneNumberPurchase(number);
                                                                            }}>
                                                                            {formatPhoneNumber(number)}
                                                                        </button>
                                                                    ))}
                                                                </div>

                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        }
                                    </>}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
