import {convertTitleCaseString, extractPhoneString, formatPhoneNumber} from "./FormattingHelper";

export function parseCsvWithOnlyPhoneNumbers(data) {
    let contacts = data.map(con => {
        if (formatPhoneNumber(con[0]) && extractPhoneString(formatPhoneNumber(con[0])).length >= 10) {
            return {
                name: extractPhoneString(con[0]),
                phone: extractPhoneString(con[0]),
            }
        } else {
            return {
                name: extractPhoneString(con[0]),
                phone: extractPhoneString(con[0]),
                invalid: true
            }
        }
    });
    return contacts;
}


export function parseCsvWithNameAndPhoneNumber(data) {
    return data.map(con=>{
        if(formatPhoneNumber(con[1]) && extractPhoneString(formatPhoneNumber(con[1])).length>=10){
            return{
                name:con[0].trim(),
                phone:extractPhoneString(con[1]),
            }
        }else{
            return {
                name: con[0],
                phone: con[1],
                invalid:true
            }
        }
    })
}

export const CSV_PARSE_ERROR_INVALID_FILE = "CSV_PARSE_ERROR_INVALID_FILE";
export const CSV_PARSE_ERROR_NO_DATA = "CSV_PARSE_ERROR_NO_DATA";

export function parseCsv(data,file) {
    if(file && file.name.split('.').pop()!=="csv"){
        return {
            contacts: [],
            error: CSV_PARSE_ERROR_INVALID_FILE};
    }
    if(data && data.length>0){
        return {
            contacts: data[0] && data[0].constructor===Array && data[0].length >= 2?
                                            parseCsvWithNameAndPhoneNumber(data):
                                            parseCsvWithOnlyPhoneNumbers(data),
            err:null,
        }
    }else{
        return {
            contacts: [],
            err: CSV_PARSE_ERROR_NO_DATA,
        }
    }

}

// csvExtractHeaders returns headers from a csv file
export function csvExtractHeaders(csvDataArray) {
    if(csvDataArray!==null && csvDataArray.length===0){
        return -1;
    }
    for (let i=0;i<csvDataArray[0].length;i+=1){
        if(csvDataArray[0][i].toLowerCase()==="phone_number"){
            csvDataArray[0][i]="phone";
        }else if(csvDataArray[0][i].toLowerCase()==="phone number"){
            csvDataArray[0][i]="phone";
        } else if(csvDataArray[0][i].toLowerCase()==="telephone number"){
            csvDataArray[0][i]="phone";
        }

        if (csvDataArray[0][i].toLowerCase()==="first name") {
            csvDataArray[0][i]="name";
        }
        csvDataArray[0][i]=csvDataArray[0][i].trim();
    }
    return csvDataArray[0];
}

// csvValidateHeaders checks whether csv headers are in proper format or not
// returns -1 if header not available
//         -2 if phone_number or/and name not available
export function csvValidateHeaders(headers) {
    if (headers!==null && headers.length===0){
        return -1;
    }

    headers = headers.map(h=>h.toLowerCase());
    return headers.includes("phone")?1:-2;
}

const csv=require('csvtojson');


export function csvToJson(csvData,headers) {
    return csv({
        noheader:false,
        headers: headers,
        colParser:{
            "name":function(item){
                return convertTitleCaseString(item);
            },
            "phone":function(item, head, resultRow, row , colIdx){
                /*
                    item - "1970-01-01"
                    head - "birthday"
                    resultRow - {name:"Joe"}
                    row - ["Joe","1970-01-01"]
                    colIdx - 1
                */
                return extractPhoneString(item);
            }
        }
    })
        .fromString(csvData)
        .subscribe((csvLine)=>{
            return csvLine;
        })
}

export function compileCSVContent(rows) {
    let csvContent = "data:text/csv;charset=utf-8,";
    let header = Object.keys(rows[0]).join(",");
    csvContent += header + "\r\n";

    for(let i=0; i<rows.length; i++) {
        let row = Object.values(rows[i]).join(",");
        csvContent += row + "\r\n";
    }
    return csvContent;
}
