/* eslint-disable */
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import ImportCSVModal from "./ImportCSVModal";
import Button from "react-bootstrap/Button";
import GroupMembersViewModal from "./GroupMembersViewModal";
import {CSVLink} from "react-csv";
import Switch from "react-switch";
import {toast} from "react-toastify";
import GroupServices from "../../Services/GroupServices";

export default function GroupEditor(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [memberModalShow, setMemberModalShow] = React.useState(false);
    const [generateKeys, setGenerateKeys] = React.useState(false);
    const [responseReport, setResponseReport] = React.useState([]);
    const csvLink = React.useRef();
    const groupServices = GroupServices.getInstance();
    const getResponseReport = async () => {
        // This is different from DeliveryReport because a user can send characters in the message
        // that need to be encoded for the link href (i.e. #).
        // So to prevent having to encode and decode, this is easier
        await groupServices.responseReport(props.group.id)
            .then((rows) => {
                if (rows.length == 0) {
                    toast.info("There are no responses for this group");
                    return;
                }
                setResponseReport(rows);
            })
            .catch(() => {
                toast.error("Could not download response report.");
            });
        csvLink.current.link.click();
    };

    useEffect(() => {
        props.fetchDomains();
    }, []);

    const hasTargetUrl = props.group.members && props.group.members.length ? !!props.group.members[0].target_url : false;
    return (
        <div className={props.loading ? "hidden" : "settings-container"}>
            {/* Titlebar start */}
            <div className="conversation-title-container text-light shadow ">
                <div className="p-0 ">
                    {!props.new ?
                        <div className="row p-1 align-middle text-left">
                            <div className="col-11">
                                <Link to="/"
                                      className="btn round d-md-none">
                                    <i className="fas fa-arrow-left"/>
                                </Link>
                                <img className=" round p-2"
                                     src={"https://slytext.com" + props.group.avatar}
                                     alt=""
                                     height={50}
                                     width={50}
                                />

                                <span className="text-light  title">
                                            {props.group.name} (Group settings)
                                        </span>
                            </div>
                            <div className="col-1">
                                <div className="btn-group dropleft right">
                                    <Link to={"/groups/" + props.group.id + "/conversation"}
                                          title={"Cancel and close"}
                                          className="btn mt-2 ml-2 round right">
                                        <i className="fas fa-times text-light"/>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        :
                        <div className="row p-1 align-middle text-left">
                            <div className="col-10 p-3">
                                <Link to="/"
                                      className="btn round d-md-none">
                                    <i className="fas fa-arrow-left"/>
                                </Link>
                                <span className="text-light p-2 text-left title">
                                            Create group
                                        </span>
                            </div>
                        </div>

                    }
                </div>
            </div>
            {/* Titlebar end */}


            <div className="settings-content">
                <div className="mt-3 text-white text-black-50 mt- pl-4 pr-4 rounded text-left">
                    <h4>What is a broadcast group?</h4>
                    <p>A broadcast group allows you to send one message to multiple phone numbers at once.
                        When a recipient replies, it will be seen only by the sender and not all the recipients of
                        the
                        broadcast group</p>

                </div>
                <div className="col-12 mt-4 pl-4">
                    <form>
                        <div className="form-group row text-left">
                            <label htmlFor="inputGroupName" className="col-sm-2 col-form-label text-dark">Group
                                name:</label>
                            <div className="col-sm-10 col-md-6">
                                <input type="text"
                                       className="form-control"
                                       id="inputGroupName"
                                       placeholder="Enter group name"
                                       maxLength={40}
                                       value={props.group.name || ""}
                                       onChange={props.handleGroupNameChange}/>
                            </div>
                        </div>
                        <div className="form-group row text-left">
                            <label htmlFor="inputPhone" className="col-sm-2 col-form-label text-dark">Group
                                members:</label>
                            {!props.csvImport && props.new ?
                                <>
                                    <div className="col-sm-10 col-md-6">
                                        <textarea
                                            className="form-control"
                                            id="inputPhone"
                                            placeholder={"Format: \nName, Phone \nOR \nPhone"}
                                            rows={6}
                                            value={props.membersString}
                                            onChange={props.handleMembersChange}>
                                        </textarea>
                                        <div>
                                            {props.group.members ? props.group.members.length + " member(s) " : "0 member(s) "}
                                            | Max: {props.groupSizeLimit} contacts.
                                        </div>
                                        <br/>
                                        <div className="float-right">
                                            <Switch className="float-right" onChange={() => {
                                                props.addToGroup(!props.ui.addingToGroup);
                                            }} checked={props.ui.addingToGroup} height={20} width={40}/>
                                            <p className="float-right pr-2">Add from contacts</p>
                                        </div>
                                    </div>

                                    <br/>
                                </>
                                :
                                props.group !== undefined && props.group !== null && props.group.members !== undefined ?
                                    <div className="col-sm-10 col-md-6">

                                        <Button onClick={() => {
                                            setMemberModalShow(true);
                                        }}>
                                            View
                                        </Button>
                                        <span className={"ml-2 font-italic"}>
                                        {props.group.members.length} member{props.group.members.length > 1 ? "s" : ""}
                                    </span>
                                    </div>
                                    : ""
                            }
                        </div>
                        <div className="form-group row text-left">
                            {!props.new ?
                                <>
                                    <div className="offset-md-2 col-md-6">
                                        <button className="btn btn-secondary text-white mt-2"
                                                onClick={getResponseReport}>Response Report
                                        </button>

                                        <CSVLink
                                            data={responseReport}
                                            filename="response-report.csv"
                                            className="hidden"
                                            ref={csvLink}
                                            target="_blank"
                                        />
                                    </div>
                                </>
                                : ""
                            }
                        </div>

                        {props.new ?
                            <>
                                <div className="mt-3 text-white text-black-50 mt- pr-4 text-left">
                                    <h4>Uploading a File</h4>
                                    <p>To automatically insert custom fields into your text message,
                                        <a href={"https://slytext.com/static/Sample_slyText_Group_Import_File.csv"}> click
                                            here </a>
                                        to download a sample CSV file.</p>
                                    <p><b> Files uploaded <em>must</em> contain headers with Name and Phone number
                                        in
                                        the first and second column.</b></p>
                                </div>

                                <div className="form-group row text-left">

                                    <label className="col-sm-2 col-form-label text-dark">Import CSV:</label>


                                    <div className="col-sm-10 col-md-6  ">

                                        <ImportCSVModal
                                            set_json={(data) => {
                                                props.setCsvJson(data);
                                                setModalShow(false);
                                            }}
                                            groupSizeLimit={props.groupSizeLimit}
                                            showUrlRedirect={props.showUrlRedirect}
                                            setGenerateKeys={() => setGenerateKeys(!generateKeys)}
                                        />

                                    </div>
                                </div>
                                {generateKeys && <>
                                    <div className="form-group row">
                                        <label htmlFor="multipleDomain"
                                               className="col-sm-3 col-form-label">Domain:</label>
                                        <div className="col-sm-9">
                                            <select className="form-control"
                                                    id="multipleDomain"
                                                    name="multipleDomain"
                                                    onChange={props.handleInputChange}
                                            >
                                                <option key={"none"}
                                                        value="">-
                                                </option>
                                                {props.domains.map(record => <option key={record.domain}
                                                                                     value={record.domain}>{record.domain}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    {!hasTargetUrl && <div className="form-group row">
                                        <label htmlFor="multipleURL" className="col-sm-3 col-form-label">Target
                                            URL:</label>
                                        <div className="col-sm-9">
                                            <input type="text"
                                                   className="form-control"
                                                   id="multipleURL"
                                                   name="multipleURL"
                                                   placeholder="Enter URL"
                                                   onChange={props.handleInputChange}
                                            />
                                        </div>
                                    </div>}
                                </>}
                            </>
                            : ""
                        }

                    </form>

                </div>
            </div>
            <div className="settings-button-footer">
                <div className="row p-2">
                    <div className="col-sm-12 col-md-3 p-1">
                        {!props.new ?
                            <Link to={"/groups/" + props.group.id + "/conversation"}
                                  className="col-12 btn btn-secondary">
                                Go to conversation
                            </Link>
                            : ""
                        }
                    </div>
                    <div className="col-sm-12 col-md-2 offset-md-5 p-1">
                        {!props.new ?
                            <button className="col-12 btn btn-danger"
                                    onClick={props.handleDelete}>
                                Delete
                            </button>
                            : ""
                        }

                    </div>
                    <div className="col-sm-12 col-md-2 p-1">
                        {props.handlingSubmit ?
                            <button className="col-12 btn btn-primary" disabled>
                                Please wait...
                            </button>
                            :
                            <button className="col-12 btn btn-primary"
                                    onClick={(e) => props.handleSubmit(e, generateKeys)}>
                                Save
                            </button>
                        }
                    </div>
                </div>


                <GroupMembersViewModal
                    show={memberModalShow}
                    onHide={() => {
                        setMemberModalShow(false);
                        props.fetchGroupById(props.group.id);
                    }}
                    json={props.group.members}
                    set_json={(data) => {
                        props.setCsvJson(data);
                        setModalShow(false);
                    }}
                    deleteMembersByPhones={props.deleteMembersByPhones}
                    addMember={props.addMember}
                    onSave={props.handleSubmit}
                />
            </div>
        </div>
    );
}
